import axios from 'axios'


export default axios.create({
    baseURL: `${process.env.REACT_APP_EMPLOY}`,
    headers: {
        'platform': "agent",

        'Content-type': 'application/json'
    }
}) 

